<template>
  <pages>
    <div slot="title" data-aos="fade" data-aos-delay="300" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">Leadership</div>
    <div slot="body" class="leadership-body" v-for="(role, index) in leadershipData" :key="index">
      <p data-aos="fade" :data-aos-delay="setDelay(index)" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">{{role.title}}</p>
      <p data-aos="fade" :data-aos-delay="setDelay(index)" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">{{role.place}}</p>
    </div>
  </pages>
</template>

<script>
export default {
  name: 'Leadership',
  data () {
    return {
      leadershipData: [
        {
          title: 'Community Relations Director',
          place:'Velocity Credit Union',
        },
        {
          title:'Mayor’s Corporate Engagement Council',
          place: 'City of Austin',
        },
        {
          title: 'Director of Community Engagement',
          place:'Austin For All People',
        },
        {
          title: 'National Federal Club Co-Char & National Board of Governors Member',
          place:'Human Rights Campaign',
        },
        {
          title: 'Executive Committee Board Member',
          place:'Austin LGBT Chamber of Commerce',
        },
        {
          title: 'Emerging Leaders Executive Committee',
          place:'United Way for Greater Austin',
        },
        {
          title: 'Steering Committee Member',
          place:'Early Matters Greater Austin',
        },
        {
          title: 'Development Committee Member',
          place:'Leadership Austin',
        },
        {
          title: 'Marketing Committee Member',
          place:'ZACH Theatre',
        },
      ]
    }
  },
  methods: {
    setDelay (index) {
      return 600 + (index * 100)
    }
  }
}
</script>

<style lang="scss">
.leadership-body {
  @include flexColumn;
  line-height: 1.125;
  margin-bottom: 1em;
  p {
    font-family:'Electra LT Std', serif;
    // color: whitesmoke;
    @include textStyles(20);
    margin-bottom: 0;
    line-height: 1;
    &:nth-child(1) {
      font-weight: bold;
      letter-spacing: 0.05em;
    }
    &:nth-child(2) {
      letter-spacing: -0.025em;
    }
  }
}

</style>