<template>
  <pages :page-name="'contact'">
    <div slot="title" data-aos="fade" data-aos-delay="300" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">Contact</div>
    <p slot="body" data-aos="fade" data-aos-delay="600" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">
      I am always interested in connecting with people who are doing great things and wish to make a lasting difference in the world.  Keynote, motivational, political* or civically charged speaking opportunities are some of my favorite engagements.
    </p>
    <p class="sm-text" slot="body" data-aos="fade" data-aos-delay="800" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">
      *Political speaking opportunities and endorsements (candidate or cause) will be thoroughly reviewed and vetted before accepting.
    </p>
    <p class="email" slot="body" data-aos="fade" data-aos-delay="1000" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">nramsey.info@gmail.com</p>
    <p slot="aside" data-aos="fade" data-aos-delay="1200" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">Also find me on social media</p>
    <p slot="aside" data-aos="fade" data-aos-delay="1400" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-once="true">
      <a href="https://www.instagram.com/NicoRamsey" target="_blank">instagram</a>
      <a href="https://www.twitter.com/NicoRamsey_" target="_blank">twitter</a>
    </p>
  </pages>
</template>

<script>
export default {
  name: 'Contact'
}
</script>
<style lang="scss">
.contact aside {
  margin-top: 20%;
}
.contact .sm-text {
  @include textStyles(16);
}
.contact .email {
  color: $yellow-light;
  font-weight: bold;
}
a {
  text-decoration: none;
  color: $yellow-light;
  font-weight: bold;
  &:nth-child(1) {
    margin-right: 10px;
  }
}
</style>
