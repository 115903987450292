<template>
  <div class="media-press">
    <h1>Media & Press</h1>
    <main class="media-press-body">
      <div class="video-container" v-for="(media, index) in mediaData" :key="index">
        <video>
          <source :src="media.src">
        </video>
        <h2>{{media.title}}</h2>
        <p>{{media.date}}</p>
        <p>{{media.type}}</p>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'media-press',
  data () {
    return {
      mediaData: [
        {
        src: 'https://vimeo.com/519198014',
        title: 'All One Kin: Meet Nico Ramsey',
        date: '3/23/2021',
        type: 'video',
        },
        {
        src: 'https://www.radio.com/podcasts/the-austin-city-councilman-48889/feb-2-2021-nico-ramsey-austin-for-all-people-weak-vs-strong-mayor-357835458',
        title: 'Nico Ramsey, Austin for All People (Weak vs. Strong Mayor)',
        date: '2/21/2021',
        type: 'podcast',
        },
        {
        src: 'https://cbsaustin.com/news/local/austin-voters-to-decide-on-new-form-of-government',
        title: 'Austin voters to decide on new form of government',
        date: '2/9/2021',
        type: 'video',
        },
        {
        src: 'https://www.youtube.com/watch?v=xqpnSZpPzT8',
        title: 'Lunch with Leaders 2021 | Kristin Marcum & Nico Ramsey',
        date: '1/29/2021',
        type: 'video',
        },
        {
        src: 'https://www.bgmediagp.com/bgpodcast/episode124',
        title: 'Episode 124: Opposing Strong Mayor Reform with Austin for All People',
        date: '1/20/2021',
        type: 'podcast',
        },
        {
        src: 'https://www.austinmonitor.com/stories/2021/01/groups-ready-to-battle-over-strong-mayor/',
        title: 'Groups ready to battle over strong mayor',
        date: '1/12/2021',
        type: 'print',
        },
        {
        src: 'https://soundcloud.com/user-796851357/episode-3-nico-ramsey',
        title: 'Voice of our Future Ep. 3 ',
        date: '12/6/2020',
        type: 'podcast',
        },
        {
        src: 'https://www.kxan.com/studio-512/velocity-credit-union-continues-to-serve-members-pets-and-the-community-during-pandemic/',
        title: 'Velocity Credit Union Continues To Serve Members, Pets and The Community During Pandemic',
        date: '8/20/2020',
        type: 'video',
        },
        {
        src: 'https://www.austinchronicle.com/arts/2020-06-19/a-year-later-the-time-is-right-for-another-conversation-about-notes-from-the-field/',
        title: 'A Year Later, the Time Is Right for Another Conversation About Notes From the Field…',
        date: '6/19/2020',
        type: 'print',
        },
        {
        src: 'https://www.unitedwayaustin.org/meet-nico-ramsey-emerging-leader-philanthropy-champion/',
        title: 'Meet Nico Ramsey, Emerging Leader & Philanthropy Champion',
        date: '6/12/2020',
        type: 'video',
        },
        {
        src: 'https://www.statesman.com/story/opinion/columns/your-voice/2021/02/28/opinion-strong-mayor-austin-would-obstacle-social-justice/4574600001/',
        title: 'Opinion: A strong mayor in Austin would be an obstacle to social justice',
        date: '2/28/2020',
        type: 'print',
        },
        {
        src: 'https://www.youtube.com/watch?v=_s-90_Iyqtw&t=2s',
        title: 'Austin Lives United',
        date: '9/11/2019',
        type: 'video',
        },
        {
        src: 'https://www.kxan.com/studio-512/velocity-credit-union-shares-tips-to-avoid-identity-theft/1799667825/',
        title: 'Velocity Credit Union Shares Tips to Avoid Identity Theft',
        date: '2/22/2019',
        type: 'video',
        },
        {
        src: 'https://www.austin360.com/entertainmentlife/20190127/grassroots-power-rallies-austins-human-rights-campaign-gala-with-hannah-gadsby',
        title: 'Grassroots power rallies Austin’s Human Rights Campaign Gala with Hannah Gadsby',
        date: '1/27/2019',
        type: 'print',
        },
        {
        src: 'https://podcasts.apple.com/us/podcast/s2e8-yo-soy-nico-ramsey/id1323567331?i=1000421414453',
        title: 'S2 E8 Yo Soy Nico Ramsey ',
        date: '10/9/2018',
        type: 'podcast',
        },
        {
        src: 'https://www.statesman.com/2018/09/26/clients-were-the-stars-at-three-big-austin-benefits/',
        title: 'Clients were the stars at three big Austin benefits',
        date: '9/26/2018',
        type: 'print',
        },
        {
        src: 'https://www.kxan.com/studio-512/help-to-clear-the-shelters-with-velocity-credit-union/1378143529/',
        title: 'Help to Clear the Shelters with Velocity Credit Union',
        date: '8/17/2018',
        type: 'video',
        },
        {
        src: 'https://www.kxan.com/studio-512/popular-faq-series-with-velocity-credit-union/',
        title: 'Popular FAQ Series with Velocity Credit Union',
        date: '4/30/2018',
        type: 'video',
        },
        {
        src: 'https://www.kxan.com/studio-512/financial-literacy-month-with-velocity-credit-union/1143247865/',
        title: 'Financial Literacy Month with Velocity Credit Union',
        date: '4/26/2018',
        type: 'video',
        },
        {
        src: 'https://www.kxan.com/studio-512/velocity-credit-union-wants-your-help-to-clear-the-shelters/',
        title: 'Velocity Credit Union Wants Your Help to Clear the Shelters',
        date: '8/17/2017',
        type: 'video',
        },
      ]
    }
  }
}
</script>
<style lang="scss">
.media-press {
  @include border-type();
  @include box(100%);
  @include flexColumn;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  max-width: 1080px;
  margin-top: 80px;
  h1 {
    @include border-type();
    @include textStyles(52); //need to change to font vw for responsiveness
    line-height: .6;
  }
  &-body {
    @include border-type(green);
    @include box(100%);
    .video-container {
      @include border-type(red);
    }
  }
}
</style>
