<template>
  <div class="blog">
    <h1>This is a blog page</h1>
  </div>
</template>

<script>
export default {
  name: 'Blog'
}
</script>
<style lang="scss">
.blog {
  @include box(100%);
  @include flexColumn;
  justify-content: center;
  align-items: center;
}
</style>
